<template>
  <div>
    <div>
      <br />
      <h4 style="text-align: center; background-color: lightblue; padding: 10px; border-radius: 20px">
        Project Directory
      </h4>
      <br />
      <b-table-simple striped hover>
        <colgroup>
          <col />
          <col />
        </colgroup>
        <colgroup>
          <col />
          <col />
          <col />
        </colgroup>
        <colgroup>
          <col />
          <col />
        </colgroup>
        <b-thead head-variant="dark">
          <b-tr>
            <b-th colspan="1">Project Name</b-th>
            <b-th colspan="1">Show Issue</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(project, index) in projects" :key="`project-${index}`" cols="3">
            <b-td variant="warning">{{ project.name }}</b-td>
            <b-th class="test-right">
              <b-row>
                <b-col cols="5"></b-col>
                <b-col><b-button variant="primary" @click="getIssues(project.name)">view</b-button> </b-col>
              </b-row>
            </b-th>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>
<script>
import { get } from '../../../store/utils/api'
import { atlassianJiraURL } from '../../../../env'
export default {
  name: 'Dashboard',

  data() {
    return {
      projects: [],
    }
  },
  mounted() {
    get(atlassianJiraURL + '/' + this.$store.getters.getAppID + '/rest/api/2/project').then((res) => {
      this.projects = [...res.data]
    })
  },
  methods: {
    getIssues(projectName) {
      this.$store.dispatch('setProjectNameAction', projectName)
      // get(
      //   atlassianJiraURL +
      //     '/' +
      //     this.$store.getters.getAppID +
      //     `/rest/api/2/search?jql=project = "${this.$store.getters.getProjectName}"  order by created DESC`
      // ).then((res) => {
      //   console.log(this.$store.getters.getProjectName)
      // })
      this.$router.push({ name: 'Issues' })
    },
  },
}
</script>

<style>
#backgrounds {
  background-image: url('/color.jpg');
  background-size: cover;
  align-items: center;
}
</style>
