<template>
  <div>
    <h3 style="text-align: center; background-color: skyblue; margin: 10px; padding: 10px; border-radius: 30px">
      Story Point Summary
    </h3>
    <!-- Using components -->
    <b-input-group prepend="Search by JQL" class="mt-3">
      <b-form-input v-model="jql" placeholder="example"></b-form-input>
      <b-input-group-append>
        <b-button variant="info" @click="getIssues">Search</b-button>
      </b-input-group-append>
    </b-input-group>
    <br />
    <b-container>
      <b-row>
        <b-col cols="3">
          <b-table-simple hover small caption-top responsive>
            <caption>
              TOTAL
            </caption>
            <colgroup>
              <col />
              <col />
            </colgroup>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th colspan="1">Assignee</b-th>
                <b-th colspan="1">Total Story Point</b-th>
                <b-th colspan="1">Total Remaining Time</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(assignee, index) in assignees" :key="`assignee-${index}`">
                <b-td variant="success">{{ assignee.name }}</b-td>
                <b-th>{{ assignee.story_point }}</b-th>
                <b-td variant="danger">{{ assignee.time_estimate / 3600 }}h</b-td>
              </b-tr>
            </b-tbody>
            <b-tfoot>
              <b-tr> </b-tr>
            </b-tfoot>
          </b-table-simple>
        </b-col>
        <b-col cols="9">
          <b-table-simple hover small caption-top responsive>
            <caption>
              TICKET
            </caption>
            <colgroup>
              <col />
              <col />
            </colgroup>
            <colgroup>
              <col />
              <col />
              <col />
            </colgroup>
            <colgroup>
              <col />
              <col />
            </colgroup>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th colspan="1">No</b-th>
                <b-th colspan="1">Issue</b-th>
                <b-th colspan="1">Assignee</b-th>
                <b-th colspan="1">Summary</b-th>
                <b-th colspan="1">SP</b-th>
                <b-th colspan="1">RT</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(issue, index) in issues" :key="`issue-${index}`">
                <b-td variant="success">{{ index + 1 }}</b-td>
                <b-th>{{ issue.key }}</b-th>
                <b-td variant="danger">{{ issue.fields.assignee }}</b-td>
                <b-td variant="warning">{{ issue.fields.summary }}</b-td>
                <b-td variant="danger">{{ issue.fields.customfield_10026 }}</b-td>
                <b-td variant="success">{{ issue.fields.timeestimate / 3600 }}h</b-td>
              </b-tr>
            </b-tbody>
            <b-tfoot>
              <b-tr> </b-tr>
            </b-tfoot>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-container>
    <!-- //<p>{{ issues }}</p> -->
  </div>
</template>

<script>
import { get } from '../../../store/utils/api'
import { atlassianJiraURL } from '../../../../env'
export default {
  name: 'Calculator',
  component: {},

  data() {
    return {
      jql: '',
      issues: [],
      assignees: [
        { name: 'Rasmohanrau Francis', story_point: 0, time_estimate: 0 },
        { name: 'Ng Xin Zhe', story_point: 0, time_estimate: 0 },
        { name: 'InnoQode', story_point: 0, time_estimate: 0 },
        { name: 'Salman Memon', story_point: 0, time_estimate: 0 },
        { name: 'Muhammad Nazmi Akbar', story_point: 0, time_estimate: 0 },
        { name: 'Hassan Shiman Moosa', story_point: 0, time_estimate: 0 },
        { name: 'Arvin Sanmuga', story_point: 0, time_estimate: 0 },
      ],
    }
  },

  mounted() {},
  methods: {
    async getIssues() {
      await get(atlassianJiraURL + '/' + this.$store.getters.getAppID + '/rest/api/2/search?jql=' + this.jql).then(
        (res) => {
          this.issues = res.data.issues
          console.log(res)
        }
      )

      this.update_story_point()
      this.update_remaining_time()

      for (var n = 0; n < this.issues.length; n++) {
        if (this.issues[n].fields.assignee != null) {
          this.issues[n].fields.assignee = this.issues[n].fields.assignee.displayName
        }
      }
    },
    update_story_point() {
      for (let i = 0; i < this.assignees.length; i++) {
        for (let j = 0; j < this.issues.length; j++) {
          //console.log(this.assignees[i].name)
          if (
            this.issues[j].fields.assignee != null &&
            this.assignees[i].name == this.issues[j].fields.assignee.displayName
          ) {
            if (this.issues[j].fields.customfield_10026 != null) {
              this.assignees[i].story_point += this.issues[j].fields.customfield_10026
            }
          }
        }
      }
    },
    update_remaining_time() {
      for (let i = 0; i < this.assignees.length; i++) {
        for (let j = 0; j < this.issues.length; j++) {
          if (
            this.issues[j].fields.assignee != null &&
            this.assignees[i].name == this.issues[j].fields.assignee.displayName
          ) {
            if (this.issues[j].fields.timeestimate != null) {
              this.assignees[i].time_estimate += this.issues[j].fields.timeestimate
            }
          }
        }
      }
    },
  },
}
</script>
