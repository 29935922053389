<template>
  <div>
    <br />
    <h3 style="text-align: center; background-color: skyblue; padding: 10px; border-radius: 30px">
      {{ this.$store.getters.getProjectName }}
    </h3>

    <h5 style="color: brown; background-color: pink; padding: 10px; border-radius: 20px">Issues amount: {{ total }}</h5>
    <br />

    <b-container class="bv-example-row">
      <b-row>
        <b-col cols="2">
          <div>
            <b-button-group vertical>
              <b-button variant="info" @click="getMyTicket">My ticket</b-button>
              <b-button variant="info" @click="getQAIssues">In QA</b-button>
              <b-button variant="info" @click="getReleaseIssues">Ready For Release</b-button>
              <b-button variant="info" @click="getTestingIssues">Testing</b-button>
              <b-dropdown variant="primary" right text="Issue Type">
                <b-dropdown-item @click="getAllIssues">All</b-dropdown-item>
                <b-dropdown-item @click="getToDoIssues">To Do</b-dropdown-item>
                <b-dropdown-item @click="getProgressIssues">In Progress</b-dropdown-item>
                <b-dropdown-item @click="getRejectedIssues">Rejected</b-dropdown-item>
                <b-dropdown-item @click="getDoneIssues">Done</b-dropdown-item>
              </b-dropdown>
            </b-button-group>
          </div>
        </b-col>
        <b-col>
          <div>
            <b-table-simple hover small caption-top responsive>
              <caption>
                Amount Of
                {{
                  status
                }}
                -->
                {{
                  this.$store.getters.getProjectName
                }}
                :
                {{
                  total
                }}
              </caption>
              <colgroup>
                <col />
                <col />
              </colgroup>
              <colgroup>
                <col />
                <col />
                <col />
              </colgroup>
              <colgroup>
                <col />
                <col />
              </colgroup>
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th colspan="1">No</b-th>
                  <b-th colspan="1">Issue</b-th>
                  <b-th colspan="1">Summary</b-th>
                  <b-th colspan="1">Status</b-th>
                  <b-th colspan="1">Assignee</b-th>
                  <b-th colspan="1">Reporter</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(issue, index) in test" :key="`issue-${index}`">
                  <b-td variant="success">{{ index + 1 }}</b-td>
                  <b-th>{{ issue.key }}</b-th>
                  <b-td class="text-right">{{ issue.fields.summary }}</b-td>
                  <b-td variant="danger">{{ issue.fields.status.name }}</b-td>
                  <b-td variant="warning">{{ issue.fields.assignee }}</b-td>
                  <b-td variant="success">{{ issue.fields.reporter.displayName }}</b-td>
                </b-tr>
              </b-tbody>
              <b-tfoot>
                <b-tr> </b-tr>
              </b-tfoot>
            </b-table-simple>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { get } from '../../../store/utils/api'
import { atlassianJiraURL } from '../../../../env'

export default {
  name: 'Issues',
  components: {},

  data() {
    return {
      test: [],
      total: '',
      status: '',
    }
  },

  mounted() {
    this.getAllIssues()
  },

  methods: {
    //Get ticket that assign to currentUser (me) within this project
    async getMyTicket() {
      await get(
        atlassianJiraURL +
          '/' +
          this.$store.getters.getAppID +
          `/rest/api/2/search?jql=project = "${this.$store.getters.getProjectName}" AND assignee=currentUser() order by created DESC`
      ).then((res) => {
        this.test = res.data.issues
        this.total = res.data.total
      })
      for (var n = 0; n < this.test.length; n++) {
        if (this.test[n].fields.assignee != null) {
          this.test[n].fields.assignee = this.test[n].fields.assignee.displayName
        }
      }
      this.status = 'My Ticket'
    },
    //get all the ticket within this project
    async getAllIssues() {
      await get(
        atlassianJiraURL +
          '/' +
          this.$store.getters.getAppID +
          `/rest/api/2/search?jql=project = "${this.$store.getters.getProjectName}"  order by created DESC`
      ).then((res) => {
        this.test = res.data.issues
        this.total = res.data.total
      })
      for (var n = 0; n < this.test.length; n++) {
        if (this.test[n].fields.assignee != null) {
          this.test[n].fields.assignee = this.test[n].fields.assignee.displayName
        }
      }
      this.status = 'All Issues'
    },
    //get the ticket that the status is Done within this project
    async getDoneIssues() {
      await get(
        atlassianJiraURL +
          '/' +
          this.$store.getters.getAppID +
          `/rest/api/2/search?jql=project = "${this.$store.getters.getProjectName}"  AND STATUS = DONE order by created DESC`
      ).then((res) => {
        this.test = res.data.issues
        this.total = res.data.total
      })
      for (var n = 0; n < this.test.length; n++) {
        if (this.test[n].fields.assignee != null) {
          this.test[n].fields.assignee = this.test[n].fields.assignee.displayName
        }
      }
      this.status = 'Issues Done'
    },
    //get the ticket that is In Progress within this project
    async getProgressIssues() {
      await get(
        atlassianJiraURL +
          '/' +
          this.$store.getters.getAppID +
          `/rest/api/2/search?jql=project = "${this.$store.getters.getProjectName}"  AND STATUS = "In Progress" order by created DESC`
      ).then((res) => {
        this.test = res.data.issues
        this.total = res.data.total
      })
      for (var n = 0; n < this.test.length; n++) {
        if (this.test[n].fields.assignee != null) {
          this.test[n].fields.assignee = this.test[n].fields.assignee.displayName
        }
      }
      this.status = 'Issues In Progress'
    },
    //get all the ticket that the status is To Do within this project
    async getToDoIssues() {
      await get(
        atlassianJiraURL +
          '/' +
          this.$store.getters.getAppID +
          `/rest/api/2/search?jql=project = "${this.$store.getters.getProjectName}"  AND STATUS = "To Do" order by created DESC`
      ).then((res) => {
        this.test = res.data.issues
        this.total = res.data.total
      })
      for (var n = 0; n < this.test.length; n++) {
        if (this.test[n].fields.assignee != null) {
          this.test[n].fields.assignee = this.test[n].fields.assignee.displayName
        }
      }
      this.status = 'Issues To Do'
    },
    //get all the ticket that the status is Rejected within this project
    async getRejectedIssues() {
      await get(
        atlassianJiraURL +
          '/' +
          this.$store.getters.getAppID +
          `/rest/api/2/search?jql=project = "${this.$store.getters.getProjectName}"  AND STATUS = REJECTED order by created DESC`
      ).then((res) => {
        this.test = res.data.issues
        this.total = res.data.total
      })
      for (var n = 0; n < this.test.length; n++) {
        if (this.test[n].fields.assignee != null) {
          this.test[n].fields.assignee = this.test[n].fields.assignee.displayName
        }
      }
      this.status = 'Issues Rejected'
    },
    //get all the ticket that the status is In QA within this project
    async getQAIssues() {
      await get(
        atlassianJiraURL +
          '/' +
          this.$store.getters.getAppID +
          `/rest/api/2/search?jql=project = "${this.$store.getters.getProjectName}"  AND STATUS = "In QA" order by created DESC`
      ).then((res) => {
        this.test = res.data.issues
        this.total = res.data.total
      })
      for (var n = 0; n < this.test.length; n++) {
        if (this.test[n].fields.assignee != null) {
          this.test[n].fields.assignee = this.test[n].fields.assignee.displayName
        }
      }
      this.status = 'Issues In QA'
    },
    //get all the project that the status is Release within this project.
    async getReleaseIssues() {
      await get(
        atlassianJiraURL +
          '/' +
          this.$store.getters.getAppID +
          `/rest/api/2/search?jql=project = "${this.$store.getters.getProjectName}" AND STATUS= "Ready for Release"  order by created DESC`
      ).then((res) => {
        this.test = res.data.issues
        this.total = res.data.total
      })
      for (var n = 0; n < this.test.length; n++) {
        if (this.test[n].fields.assignee != null) {
          this.test[n].fields.assignee = this.test[n].fields.assignee.displayName
        }
      }
      this.status = 'Issues Release For Release'
    },
    //get the ticket that the status is Testing within this project.
    async getTestingIssues() {
      await get(
        atlassianJiraURL +
          '/' +
          this.$store.getters.getAppID +
          `/rest/api/2/search?jql=project = "${this.$store.getters.getProjectName}" AND STATUS= "Testing"  order by created DESC`
      ).then((res) => {
        this.test = res.data.issues
        this.total = res.data.total
      })
      for (var n = 0; n < this.test.length; n++) {
        if (this.test[n].fields.assignee != null) {
          this.test[n].fields.assignee = this.test[n].fields.assignee.displayName
        }
      }
      this.status = 'Issues Testing'
    },
  },
}
</script>
