<template>
  <b-container>
    <router-view />
  </b-container>
</template>
<script>
export default {
  name: 'Container',
}
</script>
